import { combineReducers } from 'redux';

// icons

import { PROJECT_SET_AFTER_EDIT, PROJECT_SET_ALL } from '../actions';

import meta from './meta';
import loops from './loops';
import matrix from './matrix';

const combinedReducer = combineReducers({
  meta,
  matrix,
  loops
});

const reducer = (state, action) => {
  switch (action.type) {
    case PROJECT_SET_ALL: {
      return action.project;
    }
    case PROJECT_SET_AFTER_EDIT: {
      return { ...action.payload, loops: state.loops };
    }
    default:
      return combinedReducer(state, action);
  }
};

export default reducer;
