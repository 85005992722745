// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';
import cartReducer from './cart';
import projectReducer from './project/';
import newProjDetails from './newProjDetails';
import loopSearch from './loopSearch';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  project: persistReducer(
    {
      key: 'project',
      storage,
      keyPrefix: 'mantis-ts-'
    },
    projectReducer
  ),
  chat,
  calendar,
  menu,
  snackbar,
  loopSearch,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'mantis-ts-'
    },
    cartReducer
  ),
  product: productReducer,
  newProjDetails: persistReducer(
    {
      key: 'newProjDetails',
      storage,
      keyPrefix: 'mantis-ts-'
    },
    newProjDetails
  )
});

export default reducers;
