// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { AppstoreOutlined, AppstoreFilled, EditOutlined } from '@ant-design/icons';
import { InsertRowLeftOutlined, InsertRowRightOutlined, BarChartOutlined, BuildFilled } from '@ant-design/icons';

// icons
const icons = {
  AppstoreOutlined,
  AppstoreFilled,
  EditOutlined,
  InsertRowLeftOutlined,
  InsertRowRightOutlined,
  BarChartOutlined,
  BuildFilled
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const information = {
  id: 'group-info',
  title: <FormattedMessage id="information" />,
  type: 'group',
  children: [
    {
      id: 'inf-dep',
      title: <FormattedMessage id="inf-dep" />,
      type: 'item',
      url: '/inf-dep',
      icon: icons.InsertRowLeftOutlined,
      disabled: true
    },
    {
      id: 'centrality',
      title: <FormattedMessage id="centrality" />,
      type: 'item',
      url: '/centrality',
      icon: icons.BarChartOutlined,
      disabled: true
    }
  ]
};

export default information;
