import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ClickAwayListener,
  // Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import KumuIcon from 'assets/icons/kumu';
// import JavaIcon from 'assets/icons/java';

// assets
import { CloseOutlined, ExportOutlined } from '@ant-design/icons';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Export = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const project = useSelector((state) => state.project);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  //{
  //   "name": "\"Biodiversity\"",
  //   "abbreviation": "BIO",
  //   "category": "General",
  //   "id": "BIO"
  // }

  const onExportKumuClick = () => {
    const elements = project.matrix.factorNames.map((factor, index) => ({
      label: factor.name,
      abbreviation: factor.abbreviation,
      weighted_eigenvector: project.matrix.direct.eigenScores.eigenvectorCentrality[index],
      influence: project.matrix.direct.normalized[factor.name].influence,
      dependence: project.matrix.direct.normalized[factor.name].dependence,
      weight: project.matrix.direct.eigenScores.eigenvectorCentrality[index]
    }));
    const connections = [];
    const matrix = project.matrix.direct.matrix;
    for (let i = 0; i < matrix.length; i++) {
      for (let j = 0; j < matrix.length; j++) {
        if (matrix[i][j] !== 0) {
          connections.push({
            from: elements[i].label,
            to: elements[j].label,
            weight: Math.abs(matrix[i][j]),
            polarity: matrix[i][j] > 0 ? 'positive' : 'negative'
          });
        }
      }
    }
    const kumu = {
      //{"label": "A"},...
      elements,
      // {"from": "A", "to": "B", "value": 3},...
      connections
    };

    const blob = new Blob([JSON.stringify(kumu, null, 4)]);
    const alink = document.createElement('a');
    const frequencyFileDownloadUrl = URL.createObjectURL(blob);
    alink.href = frequencyFileDownloadUrl;
    alink.download = 'kumu.json';
    alink.click();
    URL.revokeObjectURL(frequencyFileDownloadUrl);
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={false}
      >
        <ExportOutlined />
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Export Matrix Information"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <IconButton size="small" onClick={handleToggle}>
                      <CloseOutlined />
                    </IconButton>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    <ListItemButton disabled={false} onClick={onExportKumuClick}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'info.dark',
                            bgcolor: 'info.lighter'
                          }}
                        >
                          <KumuIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            Export to{' '}
                            <Typography component="span" variant="subtitle1">
                              Kumu
                            </Typography>{' '}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    {/* <Divider />
                    <ListItemButton onClick={onExportKumuClick} disabled={false}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'info.dark',
                            bgcolor: 'info.lighter'
                          }}
                        >
                          <JavaIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            Export to{' '}
                            <Typography component="span" variant="subtitle1">
                              Java Search
                            </Typography>{' '}
                          </Typography>
                        }
                      />
                    </ListItemButton> */}
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Export;
