// material-ui
// import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { Box, FormControl } from '@mui/material';

// assets
// import { SearchOutlined } from '@ant-design/icons';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => (
  <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
    <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
      {/* Uncomment below to bring back the search bar. For now, we are
      using this component to fill the tool bar so that load/save/profile
      are found on the right. LOOK FOR A BETTER son.  */}
      {/* <OutlinedInput  
        size="small"
        id="header-search"
        startAdornment={
          <InputAdornment position="start" sx={{ mr: -0.5 }}>
            <SearchOutlined />
          </InputAdornment>
        }
        aria-describedby="header-search-text"
        inputProps={{
          'aria-label': 'weight'
        }}
        placeholder="Ctrl + K"
      /> */}
    </FormControl>
  </Box>
);

export default Search;
