import axios from 'axios';

const axiosServices = axios.create();

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    Promise.reject((error.response && error.response.data) || 'Wrong Services');
    console.log(error.response);
  }
);

export default axiosServices;
