// import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Box, Chip, useMediaQuery } from '@mui/material';

// project import
// import useConfig from 'hooks/useConfig';
import Search from './Search';
// import Message from './Message';
import Profile from './Profile';
// import Localization from './Localization';
// import Notification from './Notification';
import MobileSection from './MobileSection';
// import MegaMenuSection from './MegaMenuSection';
import Load from './Load';
import Save from './Save';
import Export from './Export';
import Customization from './Customization';
import { SyncOutlined } from '@ant-design/icons';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  // const { i18n } = useConfig();

  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isThinking = useSelector((state) => state.loopSearch.isThinking);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const localization = useMemo(() => <Localization />, [i18n]);

  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {!matchesXs && <Search />}
      {/* {!matchesXs && megaMenu} */}
      {/* {!matchesXs && localization} */}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {/* <Notification />
      <Message /> */}
      {isThinking && <Chip label="Performing Search" icon={<SyncOutlined spin />} variant="filled" color="primary" sx={{ mr: 30 }} />}
      <Load />
      <Save />
      <Export />

      <Customization />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
