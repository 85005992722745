// third-party
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = { foundLoops: [], primeLoops: [] };

const loops = createSlice({
  name: 'loops',
  initialState,
  reducers: {
    addLoop(state, action) {
      state.foundLoops = [action.payload, ...state.foundLoops];
    },
    removeLoop(state, action) {
      state.foundLoops = state.foundLoops.filter((value, index) => index !== action.payload);
    },
    setLoopFavorite(state, action) {
      state.foundLoops = state.foundLoops.map((value, index) =>
        index === action.payload.index ? { ...value, favorite: action.payload.favorite } : value
      );
    },
    setLoopName(state, action) {
      state.foundLoops = state.foundLoops.map((value, index) =>
        index === action.payload.index ? { ...value, name: action.payload.name } : value
      );
    },
    addPrimeLoop(state, action) {
      state.primeLoops = [action.payload, ...state.primeLoops];
    },
    removePrimeLoop(state, action) {
      state.primeLoops = state.primeLoops.filter((value, index) => index !== action.payload);
    },
    setPrimeLoopFavorite(state, action) {
      state.primeLoops = state.primeLoops.map((value, index) =>
        index === action.payload.index ? { ...value, favorite: action.payload.favorite } : value
      );
    },
    setPrimeLoopName(state, action) {
      state.primeLoops = state.primeLoops.map((value, index) =>
        index === action.payload.index ? { ...value, name: action.payload.name } : value
      );
    }
  }
});

export default loops.reducer;

export const { addLoop, removeLoop, setLoopFavorite, setLoopName, addPrimeLoop, removePrimeLoop, setPrimeLoopFavorite, setPrimeLoopName } =
  loops.actions;
