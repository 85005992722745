import { multiply } from 'mathjs';

export const calculateConnectedPercentage = (adjacency) => {
  const vCount = adjacency.length;
  const edgeCount = [].concat(...adjacency).filter((e) => e !== 0).length;

  return edgeCount / (vCount * vCount - vCount);
};

export const deepCopy = (the2DArray) => {
  let result = [];
  for (let i = 0; i < the2DArray.length; i++) {
    let innerArray = [];
    for (let j = 0; j < the2DArray[0].length; j++) {
      innerArray[j] = the2DArray[i][j];
    }
    result.push(innerArray);
  }
  return result;
};

export const buildUnweightedAdjacency = (theWeightedArray) => {
  let result = [];
  for (let i = 0; i < theWeightedArray.length; i++) {
    let innerArray = [];
    for (let j = 0; j < theWeightedArray[0].length; j++) {
      innerArray[j] = theWeightedArray[i][j] > 0 ? 1 : theWeightedArray[i][j] < 0 ? -1 : 0;
    }
    result.push(innerArray);
  }
  return result;
};

/**
 * Takes in a 2D array of numbers and then multiplies on it self
 * the number of times the iterator is set to.
 * @param {Number [][]} theArray A 2D array of numbers
 * @param {Number} theIterator The number of times to iterate
 * @returns A 2D array of the multiplied over itself
 */
export const iterate = (theArray, theIterator) => {
  let tempArray = JSON.parse(JSON.stringify(theArray));
  let signs = getSigns(theArray);
  let multipliedArray = JSON.parse(JSON.stringify(theArray));
  for (let i = 0; i < theIterator; i++) {
    multipliedArray = multiply(multipliedArray, tempArray);
  }
  for (let i = 0; i < theArray.length; i++) {
    for (let j = 0; j < theArray[0].length; j++) {
      multipliedArray[i][j] = Math.abs(multipliedArray[i][j]) * signs[i][j];
    }
  }
  return multipliedArray;
};

export const influence = (theArray, theFactors) => {
  const map = new Map();
  for (let i = 0; i < theArray.length; i++) {
    let sum = 0;
    theArray[i].forEach((element) => {
      sum += Math.abs(parseInt(element));
    });
    map.set(theFactors[i], sum);
  }
  const returnMe = new Map([...map.entries()].sort((a, b) => b[1] - a[1]));
  return returnMe;
};

export const dependence = (theArray, theFactors) => {
  const map = new Map();
  for (let i = 0; i < theArray.length; i++) {
    let sum = 0;
    for (let j = 0; j < theArray.length; j++) {
      sum += Math.abs(parseInt(theArray[j][i]));
    }
    map.set(theFactors[i], sum);
  }
  const returnMe = new Map([...map.entries()].sort((a, b) => b[1] - a[1]));
  return returnMe;
};

const getSigns = (theArray) => {
  let returnMe = [];
  for (let i = 0; i < theArray.length; i++) {
    let inner = [];
    for (let j = 0; j < theArray[0].length; j++) {
      let temp = theArray[i][j] < 0 ? -1 : 1;
      inner.push(temp);
    }
    returnMe.push(inner);
  }
  return returnMe;
};

/**
 * Function used to find the min and max value of a 2D array
 *
 * @param {*} array
 *                  an unordered 2D array of real numbers
 *                       ex: [[0, 3, -1],
 *                           [1, 0, -3],
 *                           [0, 3,  0]]
 * @param {*} getData
 *                  a function which decides how to sort the 2D array
 * @returns an object with the following key:value pairs:
 *                  min: a number representing the minimum value of the 2D array
 *                  max: a number representing the maximum value of the 2D array
 */
export const findMaxMin = (array) => {
  let min = Number.POSITIVE_INFINITY;
  let max = Number.NEGATIVE_INFINITY;
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < array.length; j++) {
      if (i !== j && array[i][j] !== 0) {
        let current = Math.abs(array[i][j]);
        if (min > current) {
          min = current;
        }
        if (max < current) {
          max = current;
        }
      }
    }
  }
  return {
    min,
    max
  };
};

/**
 * Removes the row and column associated with the input factor
 *
 * @param {*} matrix
 *                  an 2D array of integers numbers representing an adjacency matrix
 *                      ex:    [[0, 3, -1,  3,  0],
 *                              [1, 0, -3, -1,  2],
 *                              [0, 3,  0,  1, -1],
 *                              [1, 1,  1,  0,  1],
 *                              [3, 3, -3,  3,  0]]
 *
 * @param {*} factors
 *                  an  1D array of Strings. The index of the "factor names" in the array
 *                  correspond with the index of its associated row and column in the adjacency matrix
 *                      ex: ['A', 'B', 'C', 'D', 'E']
 *                        A [[0,   3,  -1,   3,   0],
 *                        B  [1,   0,  -3,  -1,   2],
 *                        C  [0,   3,   0,   1,  -1],
 *                        D  [1,   1,   1,   0,   1],
 *                        E  [3,   3,  -3,   3,   0]]
 *
 * @param {*} factor
 *                  a String representing the factor name to remove
 *                      ex: C
 *
 *
 * @returns a *NEW* 2D array representing the adjacency matrix but with *factor* removed.
 *                      ex:
 *                          ['A', 'B', 'D', 'E']
 *                        A [[0,   3,   3,   0],
 *                        B  [1,   0,  -1,   2],
 *                        D  [1,   1,   0,   1],
 *                        E  [3,   3,   3,   0]]
 */
export const removeFactor = (matrix, index) => {
  return matrix.filter((row, rowIndex) => index !== rowIndex).map((row) => row.filter((element, elementIndex) => index != elementIndex));
};

/**
 * Function used to estimate the total complexity of the feedback loop analysis
 *
 * @param {*} numFactors
 *                  yhe number of factors in this matrix
 *
 * @param {*} theLength
 *                  the max length of the loops
 *
 * @param {*} thePercentage
 *                  the percentage of all the edges used
 *
 * @returns the number of posible loops that can be found.  Used to estimate how long an analysis may take
 */
export function getComplexity(numFactors, theLength, thePercentage) {
  let length = theLength > numFactors ? numFactors : theLength;
  let returnMe = 1;
  for (let i = length - 2; i >= 0; i--) {
    returnMe *= (numFactors - i) * thePercentage;
  }
  return parseInt(returnMe);
}
