import {
  calculateConnectedPercentage,
  buildUnweightedAdjacency,
  influence as calculateInfluence,
  dependence as calculateDependence,
  iterate
} from './matrix';
import { calculateCentralityVectors } from './algorithms';
import { QUADRANT_BOTTOM_LEFT, QUADRANT_BOTTOM_RIGHT, QUADRANT_TOP_LEFT, QUADRANT_TOP_RIGHT } from 'store/reducers/project/constant';

/**
 * Loads Array from CSV formmatted string
 * @param {String} theData a csv file represented as a string
 * @returns a 2D array of Data objects which hold the data and location in the array
 * and a list of the factors
 */
export const loadArray = (theData) => {
  const lines = theData.trim().split('\n');
  let outerArray = [];
  const rawFactors = lines[0].split(',');
  let parsedFactors = [];
  for (let i = 1; i < rawFactors.length; i++) {
    let index = rawFactors[i].indexOf(':');
    parsedFactors[i - 1] = index >= 0 ? rawFactors[i].substring(index + 1).trim() : rawFactors[i].trim();
  }
  for (let i = 1; i < lines.length; i++) {
    const innerArray = [];
    const matrixData = lines[i].split(',');
    let index = matrixData[0].indexOf(':');
    if (index >= 0 && parsedFactors[i - 1] !== matrixData[0].substring(index + 1).trim()) {
      let sFactor = matrixData[0].substring(index + 1).trim();
      let longer = parsedFactors[i - 1].length > sFactor.length ? parsedFactors[i - 1] : sFactor;
      parsedFactors[i - 1] = longer;
    }
    for (let j = 1; j < matrixData.length; j++) {
      innerArray[j - 1] = parseInt(matrixData[j]);
    }
    outerArray[i - 1] = innerArray;
  }
  return JSON.stringify({
    factors: parsedFactors,
    array: outerArray
  });
};

export function loadEdgeList(theEdgeList) {
  const lines = theEdgeList
    .trim()
    .split('\n')
    .map((line) => line.split(','));
  // console.dir(lines);
  const factors = Array.from(
    new Set(
      lines.slice(1).map((line) => line[0])
      // .sort() //leave if want sorted
    )
  );

  const size = factors.length;
  const array = makeArray(size, size, 0);

  for (let i = 1; i < lines.length; i++) {
    // const line = lines[i].split(',');
    const x = factors.indexOf(lines[i][0]);
    const y = factors.indexOf(lines[i][1]);
    array[x][y] = parseInt(lines[i][2]);
  }

  return { factors, array };
}

function makeArray(w, h, val) {
  var arr = [];
  for (let i = 0; i < h; i++) {
    arr[i] = [];
    for (let j = 0; j < w; j++) {
      arr[i][j] = val;
    }
  }
  return arr;
}

export function loadProjectFromCSV(fromCsv) {
  //parsed file: {factors: [String], array: [num][num]}
  // factors: array of factor names
  // array: 2D "weighted" "directional" adjacency matrix

  const factorNames = generateFactorNames(fromCsv.factors);

  const direct = buildDirect(fromCsv.array, factorNames);

  return {
    meta: {
      name: 'No name provided',
      description: 'No description provided',
      org: 'No organization provided'
    },
    matrix: {
      factorNames: factorNames, // [{name, abbreviation, category}]
      graph: buildGraph(fromCsv.array),
      direct,
      indirect: {
        ...direct,
        indirectN: 0
      }
    },
    loops: { foundLoops: [], primeLoops: [] }
  };
}

export function buildFromProjectEdit(meta, factors, direct, indirectN) {
  return {
    meta,
    matrix: {
      factorNames: factors, // [{name, abbreviation, category}]
      graph: buildGraph(direct),
      direct: buildDirect(direct, factors),
      indirect: buildIndirect(direct, factors, indirectN)
    }
  };
}

export function createProject(genesis) {
  //create the ZERO matrix of the correct dimensions
  const matrix = Array(genesis.factors.length)
    .fill()
    .map(() => Array(genesis.factors.length).fill(0));

  //create a ONES matrix to mock the eigen scores. ZEROS results in NaN
  const ONES = Array(genesis.factors.length)
    .fill()
    .map(() => Array(genesis.factors.length).fill(1));

  // map to: [{name, abbreviation, category}]
  const factors = genesis.factors.map((factor) => ({
    name: factor.name,
    abbreviation: factor.abbreviation,
    category: factor.category
  }));

  const direct = buildDirect(ONES, factors);

  return {
    meta: {
      name: genesis.name,
      description: genesis.description,
      org: genesis.org
    },
    matrix: {
      factorNames: factors, // [{name, abbreviation, category}]
      graph: {
        unweightedAdjacency: matrix,
        percentageConnected: 0
      },
      direct: { ...direct, matrix },
      indirect: {
        ...direct,
        indirectN: 0
      }
    },
    loops: { foundLoops: [], primeLoops: [] }
  };
}

export const createChangeIndirectNAction = (matrix, factorNames, n) => {
  const indirect = buildIndirect(matrix, factorNames, n);

  return {
    type: 'SET_INDIRECT_N',
    indirect
  };
};

const regexPattern = /[^A-Za-z0-9 ]/g;
export const toAbbreviation = (name) => {
  const alphaWhiteSpaceOnly = name
    .replace(regexPattern, '')
    .toLocaleUpperCase()
    .split(' ')
    .filter((w) => w.length > 1);

  if (alphaWhiteSpaceOnly.length === 0) {
    return name.toLocaleUpperCase().repeat(3).substring(0, 3);
  } else if (alphaWhiteSpaceOnly.length === 1) {
    return alphaWhiteSpaceOnly[0].substring(0, 3).toUpperCase();
  } else if (alphaWhiteSpaceOnly.length === 2) {
    return alphaWhiteSpaceOnly[0].substring(0, 2).toUpperCase() + alphaWhiteSpaceOnly[1].substring(0, 1).toUpperCase();
  } else {
    return (
      alphaWhiteSpaceOnly[0].substring(0, 1).toUpperCase() +
      alphaWhiteSpaceOnly[1].substring(0, 1).toUpperCase() +
      alphaWhiteSpaceOnly[2].substring(0, 1).toUpperCase()
    );
  }
};

const generateFactorNames = (originalFactorNames) => {
  const toFactorObj = (long) => {
    return { name: long.trim().replaceAll('"', ''), abbreviation: toAbbreviation(long) };
  };

  const genFrequencyTable = (factorNameObjects) => {
    let frequency = new Map();

    factorNameObjects.map((name) => {
      frequency.has(name.abbreviation)
        ? frequency.set(name.abbreviation, frequency.get(name.abbreviation) + 1)
        : frequency.set(name.abbreviation, 1);
      return undefined;
    });
    return frequency;
  };

  //Ugly side-effecting function
  const adjustShortNames = (factorNameObject) => {
    let frequency = genFrequencyTable(factorNameObject);
    Array.from(frequency)
      .filter(([, value]) => value > 1)
      .map(([name]) => name)
      .forEach((abbreviation) => {
        let count = 1;
        factorNameObject
          .filter((nameObj) => nameObj.abbreviation === abbreviation)
          .forEach((nameObj) => (nameObj.abbreviation = nameObj.abbreviation + count++));
      });
  };

  const factorNames = originalFactorNames.map((factor) => toFactorObj(factor)).map((factor) => ({ ...factor, category: 'General' }));

  adjustShortNames(factorNames);

  return factorNames;
};

function buildRankMap(factorNames, influence, dependence, eigenScores) {
  let leftScores = factorNames
    .map((obj, index) => {
      return { name: obj.abbreviation, score: eigenScores.pageRankCentrality[index] };
    })
    .sort((a, b) => b.score - a.score);
  let rightScores = factorNames
    .map((obj, index) => {
      return { name: obj.abbreviation, score: eigenScores.eigenvectorCentrality[index] };
    })
    .sort((a, b) => b.score - a.score);
  const iMap = new Map(
    factorNames.map((obj) => {
      let influenceRank = Array.from(influence.keys()).indexOf(obj.name) + 1;
      let dependenceRank = Array.from(dependence.keys()).indexOf(obj.name) + 1;
      let rightEigenRank = rightScores.findIndex((element) => element.name === obj.abbreviation) + 1;
      let leftEigenRank = leftScores.findIndex((element) => element.name === obj.abbreviation) + 1;
      const info = {
        influence: influenceRank,
        dependence: dependenceRank,
        rightEigen: rightEigenRank, //influence
        leftEigen: leftEigenRank //dependence
      };

      return [obj.abbreviation, info];
    })
  );
  return iMap;
}

const buildIndirect = (matrix, factors, n) => {
  return { ...buildDirect(iterate(matrix, n), factors), indirectN: n };
};

const buildDirect = (matrix, factors) => {
  const longNames = factors.map((nameObj) => nameObj.name);
  let influence = calculateInfluence(matrix, longNames);
  let dependence = calculateDependence(matrix, longNames);
  const eigenScores = calculateCentralityVectors(matrix);

  const rankMap = Object.fromEntries(buildRankMap(factors, influence, dependence, eigenScores));
  dependence = Array.from(dependence);
  influence = Array.from(influence);

  const normInfMap = normalize(influence);
  const normDepMap = normalize(dependence);

  const normalized = Array.from(normInfMap.keys()).reduce((result, key) => {
    const inf = normInfMap.get(key);
    const dep = normDepMap.get(key);

    let quadrant;
    if (inf < 0.5 && dep < 0.5) {
      quadrant = QUADRANT_BOTTOM_LEFT;
    } else if (inf >= 0.5 && dep >= 0.5) {
      quadrant = QUADRANT_TOP_RIGHT;
    } else if (inf >= 0.5 && dep < 0.5) {
      quadrant = QUADRANT_TOP_LEFT;
    } else if (inf < 0.5 && dep >= 0.5) {
      quadrant = QUADRANT_BOTTOM_RIGHT;
    }

    result[key] = { influence: inf, dependence: dep, quadrant: quadrant };
    return result;
  }, {});

  return {
    matrix,
    influence,
    dependence,
    normalized,
    eigenScores,
    rankMap
  };
};

const buildGraph = (matrix) => {
  const adjacency = buildUnweightedAdjacency(matrix);
  return {
    unweightedAdjacency: adjacency,
    percentageConnected: calculateConnectedPercentage(adjacency)
  };
};

/**
 * Normalizes data for displacement graph of values
 * @param {Map} theMap A keyset of factors which map to values of strength
 * @returns A normalized map which which values are normalized between 0 and 1
 */
const normalize = (theMap) => {
  let min = theMap[0][1];
  let max = theMap[0][1];

  theMap.forEach((pair) => {
    min = Math.min(min, pair[1]);
    max = Math.max(max, pair[1]);
  });
  const coef = max - min;
  const returnMe = new Map(theMap.map((pair) => [pair[0], (pair[1] - min) / coef]));

  return returnMe;
};

export const factorInformation = (theShortName, theProject) => {
  const factors = theProject.matrix.factorNames;

  const index = factors.reduce((found, factorObj, index) => (factorObj.abbreviation === theShortName ? index : found), -1);

  // const label = theShortName;
  // console.group(label);
  // console.info(index);
  // console.info(label);
  // console.info(factors[index].name);
  // console.info(theProject.matrix.direct.eigenScores.eigenvectorCentrality[index]);
  // console.info(theProject.matrix.direct.eigenScores.pageRankCentrality[index]);
  // console.info(theProject.matrix.direct.influence.filter((factor) => factor[0] === factors[index].name)[0][1]);
  // console.info(theProject.matrix.direct.dependence.filter((factor) => factor[0] === factors[index].name)[0][1]);

  // // matrix.direct.eigenScores.eigenvalue
  // // matrix.direct.eigenScores.eigenvectorCentrality
  // // matrix.direct.eigenScores.pageRankCentrality
  // // direct.influence

  // console.groupEnd(label);

  return {
    name: factors[index].name,
    abbreviation: theShortName,
    eigenvectorCentralityInfluence: theProject.matrix.direct.eigenScores.eigenvectorCentrality[index],
    eigenvectorCentralityDependence: theProject.matrix.direct.eigenScores.pageRankCentrality[index],
    influence: theProject.matrix.direct.influence.filter((factor) => factor[0] === factors[index].name)[0][1],
    dependence: theProject.matrix.direct.dependence.filter((factor) => factor[0] === factors[index].name)[0][1]
  };
};
