import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - sample page

const Direct = Loadable(lazy(() => import('pages/matrix-pages/direct')));
const Indirect = Loadable(lazy(() => import('pages/matrix-pages/indirect')));
// const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
// const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
const NewProject = Loadable(lazy(() => import('pages/new-project-pages/new-project')));
const EditProject = Loadable(lazy(() => import('pages/edit-project-pages/edit-project')));

// ==============================|| MAIN ROUTING ||============================== //

const MatrixRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'direct',
          element: <Direct />
        },
        {
          path: 'indirect',
          element: <Indirect />
        },
        {
          path: 'edit',
          element: <EditProject />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <CommonLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'new',
          element: <NewProject />
        }
      ]
    }
  ]
};

export default MatrixRoutes;
