// third-party
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  isThinking: false
};

const loopSearch = createSlice({
  name: 'thinking',
  initialState,
  reducers: {
    startThinking(state) {
      state.isThinking = true;
    },
    stopThinking(state) {
      state.isThinking = false;
    }
  }
});

export default loopSearch.reducer;

export const { startThinking, stopThinking } = loopSearch.actions;
