import dashboard from './dashboard';
import matrix from './matrix';
import information from './information';
import analyze from './analyze';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, matrix, information, analyze]
};

export default menuItems;
