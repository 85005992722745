import axios from 'axios';

// project imports
// import axios from 'utils/axios';
import { dispatch } from '../../index';
import { stopThinking } from '../loopSearch';
import { addLoop, addPrimeLoop } from './loops';

const matrix = (state = {}, action) => {
  switch (action.type) {
    case 'FULL_SET_PROJECT':
      return action.project;
    case 'SET_INDIRECT_N':
      return {
        ...state,
        indirect: action.indirect
      };
    case 'TEST':
      console.log('Hey you got here! Magic');
      return state;
    case 'TEST-MSG':
      console.log('Hey you got here! Magic');
      console.log(action.message);
      return state;
    default:
      return state;
  }
};

export default matrix;

export function getHello() {
  console.log('hello getHello');
  return async () => {
    //         await axios.get(
    //           `https://cfb3-tcss450-labs-helloworld.herokuapp.com/hello`,
    //         ).then(result => {
    //             //we literally don't care
    //         }).catch(err => {
    //             //TODO Add error handling
    //             console.log(err)
    //         })
    //       }

    await axios
      .get('https://uwt-set-pfms.herokuapp.com/hello')
      .then((response) => {
        console.log(response);
        dispatch({ type: 'TEST-MSG', message: response.data.message });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'TEST-MSG', message: 'Error' });
      });
  };
}

export function getLoops(options) {
  return async () => {
    await axios
      .post('https://uwt-set-pfms.herokuapp.com/calculateLoops', options)
      // .post('http://localhost:5000/calculateLoops', options)
      .then((response) => {
        console.log(options);
        console.log(response.data.search.loops.both);

        const getWeight = genGetWeightFunction(options.factorNames, options.matrix);

        const both = response.data.search.loops.both.map((loopInfo) => ({ ...loopInfo, weights: findWeights(loopInfo.loop, getWeight) }));
        const balancing = response.data.search.loops.balancing.map((loopInfo) => ({
          ...loopInfo,
          weights: findWeights(loopInfo.loop, getWeight)
        }));
        const reinforcing = response.data.search.loops.reinforcing.map((loopInfo) => ({
          ...loopInfo,
          weights: findWeights(loopInfo.loop, getWeight)
        }));

        const loops = { ...response.data.search.loops, both, balancing, reinforcing };
        response.data.search = { ...response.data.search, loops };

        dispatch(stopThinking());
        dispatch(addLoop({ ...response.data.search, favorite: false, name: 'Unnamed Search Results' }));
      })
      .catch((error) => {
        dispatch(stopThinking());
        console.log('Response received! shit 400');
        console.log(error);
        // dispatch({ type: 'TEST-MSG', message: 'Error' });
      });
  };
}

export function getAllLoops(options) {
  return async () => {
    // console.log(options);

    await axios
      // .post('http://localhost:3000/search', { ...options, length: options.maxLoopLength })
      .post('https://f114a3jmv0.execute-api.us-west-2.amazonaws.com/Prod/{proxy+}', { ...options, length: options.maxLoopLength })
      .then((response) => {
        const getWeight = genGetWeightFunction(options.factorNames, options.matrix);

        const primeLoop = {
          both: {
            ...response.data.search.loops.prime.primeLoop.both,
            weights: findWeights(response.data.search.loops.prime.primeLoop.both.loop, getWeight)
          },
          balancing: {
            ...response.data.search.loops.prime.primeLoop.balancing,
            weights: findWeights(response.data.search.loops.prime.primeLoop.balancing.loop, getWeight)
          },
          reinforcing: {
            ...response.data.search.loops.prime.primeLoop.reinforcing,
            weights: findWeights(response.data.search.loops.prime.primeLoop.reinforcing.loop, getWeight)
          }
        };

        const secondaryLoops = {
          both: response.data.search.loops.prime.secondaryLoops.both.map((loopObj) => ({
            ...loopObj,
            weights: findWeights(loopObj.loop, getWeight)
          })),
          balancing: response.data.search.loops.prime.secondaryLoops.balancing.map((loopObj) => ({
            ...loopObj,
            weights: findWeights(loopObj.loop, getWeight)
          })),
          reinforcing: response.data.search.loops.prime.secondaryLoops.reinforcing.map((loopObj) => ({
            ...loopObj,
            weights: findWeights(loopObj.loop, getWeight)
          }))
        };

        const loopsByLength = Object.fromEntries(
          Object.entries(response.data.search.loops.loopsByLength).map(([length, loopsObj]) => {
            return [
              length,
              {
                primeLoops: {
                  both: { ...loopsObj.primeLoops.both, weights: findWeights(loopsObj.primeLoops.both.loop, getWeight) },
                  balancing: { ...loopsObj.primeLoops.balancing, weights: findWeights(loopsObj.primeLoops.balancing.loop, getWeight) },
                  reinforcing: { ...loopsObj.primeLoops.reinforcing, weights: findWeights(loopsObj.primeLoops.reinforcing.loop, getWeight) }
                },
                secondaryLoops: {
                  both: loopsObj.secondaryLoops.both.map((loopObj) => ({
                    ...loopObj,
                    weights: findWeights(loopObj.loop, getWeight)
                  })),
                  balancing: loopsObj.secondaryLoops.balancing.map((loopObj) => ({
                    ...loopObj,
                    weights: findWeights(loopObj.loop, getWeight)
                  })),
                  reinforcing: loopsObj.secondaryLoops.reinforcing.map((loopObj) => ({
                    ...loopObj,
                    weights: findWeights(loopObj.loop, getWeight)
                  }))
                },
                secondaryLoopsByLength: {
                  both: loopsObj.secondaryLoopsByLength.both.map((loopObj) => ({
                    ...loopObj,
                    weights: findWeights(loopObj.loop, getWeight)
                  })),
                  balancing: loopsObj.secondaryLoopsByLength.balancing.map((loopObj) => ({
                    ...loopObj,
                    weights: findWeights(loopObj.loop, getWeight)
                  })),
                  reinforcing: loopsObj.secondaryLoopsByLength.reinforcing.map((loopObj) => ({
                    ...loopObj,
                    weights: findWeights(loopObj.loop, getWeight)
                  }))
                }
              }
            ];
          })
        );

        response.data.search.loops = { ...response.data.search.loops, prime: { primeLoop, secondaryLoops }, loopsByLength };

        dispatch(stopThinking());
        dispatch(addPrimeLoop({ ...response.data.search, favorite: false, name: 'Unnamed Prime Search' }));
      })
      .catch((error) => {
        dispatch(stopThinking());
        console.log('Response received! shit 400');
        console.log(error);
        // dispatch({ type: 'TEST-MSG', message: 'Error' });
      });
  };
}

export function getAllLoopsV2(options) {
  return async () => {
    await axios
      // .post('https://uwt-set-pfms.herokuapp.com/calculateLoops/allV2', options)
      .post('http://localhost:5000/calculateLoops/allV2', options)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log('Response received! shit 400');
        console.log(error);
        // dispatch({ type: 'TEST-MSG', message: 'Error' });
      });
  };
}

const findWeights = (loop, getWeight) => {
  let temp = [...loop];
  temp.push(loop[0]);

  return temp
    .map((current, index, { length }) => {
      if (index !== length - 1) {
        return getWeight(current, temp[index + 1]);
      }
    })
    .slice(0, loop.length);
};

function genGetWeightFunction(theFactors, theWeights) {
  let m = new Map();
  theFactors.forEach((e, i) => m.set(e, i));

  return (a, b) => parseInt(theWeights[m.get(a)][m.get(b)]); //create a closure on m!
}
