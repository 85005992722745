// action - state management
import {
  CREATE_PROJECT_SET_META_DESCRIPTION,
  CREATE_PROJECT_SET_META,
  CREATE_PROJECT_SET_META_NAME,
  CREATE_PROJECT_SET_META_ORG,
  CREATE_PROJECT_RESET
} from './actions';
import { CREATE_PROJECT_SET_FACTORS } from './actions';
import { CREATE_PROJECT_SET_ALL } from './actions';

// initial state
export const initialState = {
  name: '',
  description: '',
  org: '',
  factors: [],
  edit: false
};

// ==============================|| Create Project REDUCER ||============================== //

export default function actionReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PROJECT_SET_META: {
      return {
        ...state,
        name: action.payload.name,
        description: action.payload.description,
        org: action.payload.org
      };
    }
    case CREATE_PROJECT_SET_META_NAME: {
      return {
        ...state,
        name: action.payload.name
      };
    }
    case CREATE_PROJECT_SET_META_ORG: {
      return {
        ...state,
        org: action.payload.org
      };
    }
    case CREATE_PROJECT_SET_META_DESCRIPTION: {
      return {
        ...state,
        description: action.payload.description
      };
    }
    case CREATE_PROJECT_SET_FACTORS: {
      return {
        ...state,
        factors: action.payload.factors
      };
    }
    case CREATE_PROJECT_SET_ALL: {
      return {
        ...state,
        name: action.payload.name,
        description: action.payload.description,
        org: action.payload.org,
        factors: action.payload.factors
      };
    }
    case 'EDIT': {
      return {
        ...state,
        edit: action.payload.edit
      };
    }
    case CREATE_PROJECT_RESET: {
      return initialState;
    }
    default: {
      return { ...state };
    }
  }
}
