// third-party
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  name: 'default',
  description: 'default',
  org: 'default'
};

const meta = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setName(state, action) {
      state.name = action.payload;
    },

    editName(state, action) {
      state.name = action.payload;
    },

    setDescription(state, action) {
      state.description = action.payload;
    },

    editDescription(state, action) {
      state.description = action.payload;
    },

    setMeta(state, action) {
      state.name = action.payload.name;
      state.description = action.payload.description;
    },

    editMeta(state, action) {
      state.name = action.payload.name;
      state.description = action.payload.description;
    }
  }
});

export default meta.reducer;

export const { setMeta } = meta.actions;
