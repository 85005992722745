import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// import { HARD_CODE_PROJECT } from 'store/reducers/actions';

// assets
import { FileTextOutlined, CloseOutlined, CloudServerOutlined, FileAddOutlined, FileAddFilled } from '@ant-design/icons';

import { loadArray, loadProjectFromCSV, loadEdgeList } from 'utils/project';
import { PROJECT_SET_ALL } from 'store/reducers/actions';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Load = () => {
  const doFileUpload = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const showFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        let name = document.getElementById('fileInput').files.item(0).name.split('.');
        let extension = name[name.length - 1];
        if (extension === 'csv') {
          if (e.target.result.toLowerCase().startsWith('from')) {
            // console.dir(e.target.result);
            const res = loadEdgeList(e.target.result);
            console.dir(res);
            const project = loadProjectFromCSV(res);
            dispatch({
              type: PROJECT_SET_ALL,
              project
            });
            navigate('/dashboard');
          } else {
            const res = JSON.parse(loadArray(e.target.result));
            console.dir(res);
            const project = loadProjectFromCSV(res);
            dispatch({
              type: PROJECT_SET_ALL,
              project
            });
            navigate('/dashboard');
          }
        } else if (extension === 'pmf') {
          // console.log(e.target.result);
          const project = JSON.parse(e.target.result);
          // const project = loadProjectFromCSV(res);
          dispatch({
            type: PROJECT_SET_ALL,
            project
          });
          navigate('/dashboard');
        }
      } catch (error) {
        console.dir(error);
        setErrorMessage(error.stack);
        setErrorOpen(true);
      }
    };
    reader.readAsText(e.target.files[0]);
  };

  const onLoadClick = () => doFileUpload.current.click();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const iconBackColorOpen = 'grey.300';
  const iconBackColor = 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <input
        ref={doFileUpload}
        //hidden input to load files from
        style={{ display: 'none' }}
        type="file"
        id="fileInput"
        onChange={(e) => showFile(e)}
        accept=".csv, .pmf"
      />
      <Dialog open={errorOpen} onClose={handleErrorClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {' '}
        <DialogTitle id="alert-dialog-title">{'Opps!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oops! We encountered an error reading the file. Please open it in a text editor and look for extra commas (,) or other out of
            place characters. Copy and paste the text in red into a message to the System Administrator.
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: 'error.main'
            }}
          >
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        disableRipple
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <FileAddOutlined />
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Load Project"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <IconButton size="small" onClick={handleToggle}>
                      <CloseOutlined />
                    </IconButton>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    <ListItemButton
                      onClick={() => {
                        navigate('/new');
                        handleToggle();
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                          }}
                        >
                          <FileAddFilled />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={<Typography variant="h6">New Project!</Typography>} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={onLoadClick}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                          }}
                        >
                          <FileTextOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            Load Matrix from{' '}
                            <Typography component="span" variant="subtitle1">
                              CSV
                            </Typography>{' '}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton onClick={onLoadClick}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                          }}
                        >
                          <FileTextOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            Load Edge List from{' '}
                            <Typography component="span" variant="subtitle1">
                              CSV
                            </Typography>{' '}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton onClick={onLoadClick} disabled={false}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                          }}
                        >
                          <FileTextOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            Load from{' '}
                            <Typography component="span" variant="subtitle1">
                              PFM
                            </Typography>{' '}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton disabled={true}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                          }}
                        >
                          <CloudServerOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            Load from{' '}
                            <Typography component="span" variant="subtitle1">
                              Cloud
                            </Typography>{' '}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Load;
