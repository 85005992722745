// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { AppstoreOutlined, AppstoreFilled, EditOutlined } from '@ant-design/icons';

// icons
const icons = {
  AppstoreOutlined,
  AppstoreFilled,
  EditOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const matrix = {
  id: 'group-matrix',
  title: <FormattedMessage id="matrix" />,
  type: 'group',
  children: [
    {
      id: 'direct',
      title: <FormattedMessage id="direct" />,
      type: 'item',
      url: '/direct',
      icon: icons.AppstoreOutlined,
      disabled: true
    },
    {
      id: 'indirect',
      title: <FormattedMessage id="indirect" />,
      type: 'item',
      url: '/indirect',
      icon: icons.AppstoreFilled,
      disabled: true
    },
    {
      id: 'edit',
      title: <FormattedMessage id="edit" />,
      type: 'item',
      url: '/edit',
      icon: icons.EditOutlined,
      disabled: true
    }
  ]
};

export default matrix;
