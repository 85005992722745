import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - sample page

// const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
const Influence = Loadable(lazy(() => import('pages/information-pages/influence')));

// ==============================|| MAIN ROUTING ||============================== //

const InfoRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'inf-dep',
          element: <Influence />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <CommonLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'centrality',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default InfoRoutes;
