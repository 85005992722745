export const QUADRANT_TOP_LEFT = '@project/quadrant/tl';
export const QUADRANT_TOP_RIGHT = '@project/quadrant/tr';
export const QUADRANT_BOTTOM_LEFT = '@project/quadrant/bl';
export const QUADRANT_BOTTOM_RIGHT = '@project/quadrant/br';

import {
  BuildFilled,
  DollarCircleOutlined,
  TeamOutlined,
  HomeOutlined,
  ToolOutlined,
  ThunderboltOutlined,
  CloudOutlined
} from '@ant-design/icons';

export const factorCategoryMap = new Map([
  ['General', { category: 'General', icon: <BuildFilled rotate={90} style={{ paddingRight: '0.75em' }} /> }],
  ['Financial', { category: 'Financial', icon: <DollarCircleOutlined style={{ paddingRight: '0.75em' }} /> }],
  ['Group', { category: 'Group', icon: <TeamOutlined style={{ paddingRight: '0.75em' }} /> }],
  ['Weather', { category: 'Weather', icon: <CloudOutlined style={{ paddingRight: '0.75em' }} /> }],
  ['Electrical', { category: 'Electrical', icon: <ThunderboltOutlined style={{ paddingRight: '0.75em' }} /> }],
  ['Equipment', { category: 'Equipment', icon: <ToolOutlined style={{ paddingRight: '0.75em' }} /> }],
  ['Construction', { category: 'Construction', icon: <HomeOutlined style={{ paddingRight: '0.75em' }} /> }]
]);
