// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { AppstoreOutlined, AppstoreFilled, EditOutlined } from '@ant-design/icons';
import {
  InsertRowLeftOutlined,
  InsertRowRightOutlined,
  BarChartOutlined,
  BuildFilled,
  GatewayOutlined,
  ReloadOutlined,
  MonitorOutlined
} from '@ant-design/icons';
import RepeatOneOutlinedIcon from '@mui/icons-material/RepeatOneOutlined';

// icons
const icons = {
  AppstoreOutlined,
  AppstoreFilled,
  EditOutlined,
  InsertRowLeftOutlined,
  InsertRowRightOutlined,
  BarChartOutlined,
  BuildFilled,
  GatewayOutlined,
  ReloadOutlined,
  MonitorOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const analyze = {
  id: 'group-analyze',
  title: <FormattedMessage id="analyze" />,
  type: 'group',
  children: [
    {
      id: 'search',
      title: <FormattedMessage id="search" />,
      type: 'item',
      url: '/search',
      icon: icons.MonitorOutlined,
      disabled: true
    },
    {
      id: 'prime',
      title: <FormattedMessage id="prime" />,
      type: 'item',
      url: '/prime',
      icon: RepeatOneOutlinedIcon,
      disabled: true
    },
    {
      id: 'loops',
      title: <FormattedMessage id="loops" />,
      type: 'item',
      url: '/loops',
      icon: icons.GatewayOutlined,
      disabled: true
    },
    {
      id: 'diagram',
      title: <FormattedMessage id="diagram" />,
      type: 'item',
      url: '/diagram',
      icon: icons.ReloadOutlined,
      disabled: true
    }
  ]
};

export default analyze;
