import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - sample page

const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
const FindLoopsPage = Loadable(lazy(() => import('pages/loops/find-loops-page')));
const PrimeLoopPage = Loadable(lazy(() => import('pages/loops/find-prime-loops-page')));

// ==============================|| MAIN ROUTING ||============================== //

const AnalyzeRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'search',
          element: <FindLoopsPage />
        },
        {
          path: 'prime',
          element: <PrimeLoopPage />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <CommonLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'diagram',
          element: <MaintenanceComingSoon />
        },
        {
          path: 'loops',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default AnalyzeRoutes;
