// action - account reducer
export const LOGIN = '@auth/LOGIN';
export const LOGOUT = '@auth/LOGOUT';
export const REGISTER = '@auth/REGISTER';
export const CREATE_PROJECT_SET_META = '@create-wizard/CreateSETMeta';
export const CREATE_PROJECT_SET_META_NAME = '@create-wizard/CreateMetaName';
export const CREATE_PROJECT_SET_META_ORG = '@create-wizard/CreateMetaOrg';
export const CREATE_PROJECT_SET_META_DESCRIPTION = '@create-wizard/CreateMetaDescr';
export const CREATE_PROJECT_SET_FACTORS = '@create-wizard/FactorForm';
export const CREATE_PROJECT_RESET = '@create-wizard/Reset';
export const CREATE_PROJECT_SET_ALL = '@create-wizard/Review';
export const PROJECT_SET_ALL = '@project/SetWholeProject';
export const PROJECT_SET_AFTER_EDIT = '@project/SetProjectAfterEdit';

export const HARD_CODE_PROJECT = {
  type: 'SET_PROJECT',
  factorNames: ['ABC', 'XYZ', 'LMN', 'CFB', 'OEB', 'ARB'],
  matrix: [
    [0, 1, 3],
    [-1, 0, 0],
    [0, 3, 0]
  ]
};
