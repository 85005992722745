import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import MatrixRoutes from './MatrixRoutes';
import InfoRoutes from './InfoRoutes';
import AnalyzeRoutes from './AnalyzeRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([LoginRoutes, MainRoutes, MatrixRoutes, InfoRoutes, AnalyzeRoutes]);
}
